.footer{
    background-image: url('../../images/watercolor-light-peach-background_23-2150268724.avif');
    background-repeat: no-repeat;
    background-size: cover;
}


.section_padding{
    padding: 4rem 4rem;
}


.sb_footer{
    display: flex;
    flex-direction: column;

}

.hello{
    font-size: 50px;
    padding: 12px;
    color: rgb(231, 104, 176);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sb_footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}


.sb_footer-links-div{


    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: brown;

}

a{
    color: rgb(0, 0, 0);
    text-decoration: none;

}


.social-media{

    display: flex;
    flex-direction: row;
}

.social-media img{
    width: 80%;
}

.sb_footer-links_div h4{

    font-size: 20px;
    line-height: 17px;
    margin-bottom: 0.9em;

}



.sb_footer-links_div p{
font-size: 17px;
line-height: 15px;
margin: 0.5rem 0;
cursor: pointer;
}

.sb_footer-below{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;

}

.sb_footer-below-links{
    display: flex;
    flex-direction: row;

}

.sb_footer-below-links p{
    font-size: 16px;
    line-height: 15px;
    margin-left: 2rem;
    color: rgb(77, 67, 48);
    font-weight: 600;

}

 input{
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    width: 260px;
 }   



hr{
    background-color: #ad7f7f;
    width: 100%;
    height: 0.1px;
}


.sb_footer-copyright p{
    font-size: 16px;
    line-height: 15px;
    color: rgb(93, 93, 93);
    font-weight: 600;
    padding-top: 20px;
}


@media screen and (max-width: 850px){
    .sb_footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }
}


@media screen and (max-width: 550px){
.sb_footer-heading h1{
    font-size: 34px;
    line-height: 42px;

}

.sb_footer-links div{
    margin: 1rem 0;
}

.sb_footer-btn p{
    font-size: 14px;
    line-height: 20px;
}

.sb_footer-below{
    flex-direction: column;
    justify-content: left;
}

.sb_footer-below-links{
    flex-direction: column;
    display: flex;
    font-size: 20px;
    justify-content: space-evenly;
}

.sb_footer-below-links p{
    margin-left: 0rem;
    margin-top: 1rem;
}

}



@media screen and(max-width: 400px){

    .sb_footer-heading h1{
        font-size: 27px;
        line-height: 38px;
        
    }
    
}