.belive{
    background-image: url('../../images/watercolor-light-peach-background_23-2150268724.avif');
    height: 420px;
    background-size: cover;
    padding-top: 50px;
    /* padding-bottom: 20px; */
}

.belive  h2{
    color: black;
    padding-top: 20px;
    font-style: italic;
    font-family: cursive;
    font-size: 30px;
    text-align: center;

}


.image_contain{
    display: flex;
    gap: 3rem;
    justify-content: space-evenly;
    padding-top: 70px;
    text-align: center;
    color: rgba(75, 17, 17, 0.732);
    font-size: 15px;
    font-weight: 600;
}


.believe-text{

    width: 70px;
    /* display: flex; */
    justify-content: center;
    
}

.believe-text p{
    text-align: center;
    width: 135px;
    /* background-color: blue; */
}


.believe-text img{

    height: 120px;
    text-align: center;
    width: 120px;
}

.contain{
    padding-bottom: 60px;
}

