.ban-image img{

    height: 550px;
    width:  100%;
    padding-left: 160px;

}


.second-ban{
    padding-bottom: 80px;
    grid-template-columns: auto auto auto;
    padding: 10px;
    display: grid;
    column-gap: 90px;
}

.product-data h1{
    font-family: cursive;
    font-size: 30px;
}

.product-data{
 padding-top: 60px;
    width: 500px;
}