.detail-section{

    grid-template-columns: auto auto auto;
    padding: 10px;
    display: grid;
    column-gap: 90px;
    }


.headingsvalue{
    padding-top: 0;
    padding-bottom: 40px;
    
}    


.product-data h3{

    color: black;
    font-weight: 200;
    font-size: 30px;
}


.product-image img{

    opacity: 1;
    background-color: blueviolet;
    height: 600px;
}


.offer{

    color: var(--secondary-hue);
    font-family: cursive;
    font-style: italic;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }


  .product-data{
     display: grid;
     gap: 1rem;
  }


  .hr{

    border-bottom: 1px solid rgb(219, 214, 214);
  }


  .taxes{
    font-size: 12px;
  }


  .price{

    font-size: 20px;
    color: var(--secondary-hue);
    padding: 2px;
  }


  .cartinc{

     border: 1px solid rgb(183, 181, 181); 
    width: fit-content;
    padding: 13x;

    display: inline-flex;
    align-items: center;
    /* border: 1px solid var(--border-color); */
    white-space: nowrap;
    
    font-size: 20px;
    
  }

  .cartinc input{
    /* background: red; */
    width : 30px;
    text-align: center;
    padding: 7px;
    font-size: 20px;
  }   



  .container{
    padding-bottom: 100px;
  }
  