.instagram h1{
    font-family: cursive;
    font-size: 35px;
    text-align: center;
    padding-top: 40px;
}


.instagram h2{
    font-family: cursive;
    font-size: 25px;
    text-align: center;
 
    color: black;
}

.insta-image {

    /* padding-top: 30px; */
    display: flex;
    /* height: 400px; */
    /* width: 400px; */
    justify-content: center;
    gap: 2rem;
    padding: 40PX 20PX;
  
}

.insta-image .img_div{
    width: 400px;
    position: relative;
}

    .blur{
        position: absolute;
      
      top: 0;
    
      
        width: 100%;
        height: 100%;
    }

    .blur:hover{
        background: rgba(20, 20, 20, 0.2);
    }
.blur:hover .in{
display: initial;
}
    .blur small{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #fff;
        font-size:30px;

    }

    .in{
        display: none;
    }

.insta-image .img_div img{
    width: 100%;
    height: 100%;
}

