.card-container{
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.explore {

    font-size: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    justify-content: center;
    display: flex;
    
}


