.top_nav{
    display: flex;
    justify-content: space-between;
}

.top_nav .logo img{
    width: 11rem;
}
.blank{
    width: 10rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;   
}

nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    padding: 10px 0px 10px 0px;
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    transition: var(--transition);
    position: fixed;
    gap: 1rem;
   
}

.nav_links a:hover {
    color: rgb(255, 140, 169);
}

.nav_links a{
    transition: var(--transition);
}

.nav__togle-btn{
    display: none;
}

nav:hover{
    background: #fff;
}

.nav_links{
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.user_links{
    display: flex;
    gap: 2rem;
    padding: 1rem;
    padding-right: 40px;
  
}

.user_links .icon{
  font-size: 20px;
  font-weight: bolder;
}

.nav_links ul{
display: flex;
list-style: none;
gap: 2rem;
}

.active-nav {
position: relative;
}

.colorChange{
    background-color:  #fff;
    border-bottom: 1px solid rgb(220, 218, 218);
}

.active-nav::after{
content: '';
display: block;
width: 1.2rem;
height: 1.2rem;
/* background: red; */
position: absolute;
left: calc(50% - 0.6rem);
transform: rotate(45deg);
margin-top: 0.9rem;
}

@media screen and (max-width: 1024px) {
    .nav__togle-btn{
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav__togle-btn{
       
     color: rgb(232, 126, 126);

    }

   .nav_links{
        position: absolute;
        top: 100%;
        left: 0;
        flex-direction: column;
        gap: 0;

    } 

    .active-nav, .active-nav::after {
        display: none;
    }

    .nav_links li{
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem  5rem rgba(0,0,0,0.4);
    }

    .nav_links li a {
        background-color: #FADCD9;
        height: 100%;
        color: #4e0f08;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;

    }

    .show_nav {
        position: absolute;
        top: 100%;
        left: 0;
        transition: 1s ease;
        width: 100%;
    }

    

    .hide_nav {
        position: absolute;
        top: 100%;
        left: -100%;
        transition: 2s ease-in-out;
        width: 100%;
    }
}