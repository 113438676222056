*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
   
    font-family: 'Inter', sans-serif;
   
    word-spacing: 3px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}


:root{

  --primary-hue: #FED8C3;
  /* --gray-hue: #cea1ae; */
--secondary-hue: #d68144;

  --transition: all 500ms ease;

  --button: #fcd3b8;


}


body{
    color: var(--color-gray-200);
    font-family: 'Darker Grotesque', sans-serif;
    font-family: 'Inter', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
    line-height: 1.7;
    word-spacing: 3px;
    letter-spacing: 1px;
    overflow-x: hidden;
    background:  var(--color-gray-600);

}


.container{

    width: 80%;
    max-width: 1920px;
    margin-inline: auto;
}

h1, h2, h3 , h4, h5{
    /* line-height: 0.5; */
    color: var(--secondary-hue);
    font-weight: 500;
} 


h1{
    font-size: 3rem;
}

h2{
    font-size: 3rem;
}

a{
    color: rgb(13, 13, 13);
}

img{

    display: block;
     
}

.button{

    background: var(--button);
    width:  fit-content;
    padding: 12px 24px;
    font-size: 15px;
    border: 1px solid transparent;
    transition: var(--transition);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.3rem;

}


.button:hover{

    background: transparent;
    border: 1px solid var(--secondary-hue);

}

.head{

    font-size: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    color: black;
}

.card{
align-items: center;    
display: flex;
flex-direction:  column;
gap: 0.2rem;
position: relative;
width: 20%;
/* background: red; */
}

.card img{
    /* position: relative; */
    width: 100%;
   
}

.card small{

    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    padding: 2px 3px;
    color: #6A6A6A;
    background-color: #fffaf6;
    text-transform: uppercase;
    font-weight: 600;
}


.price span {

    text-decoration: line-through;
    color: black;
}


.category {
    width: 33.33%;
    position: relative;
    overflow: hidden;
   

}

.category img{
    
    width: 100%;
    background-size: cover;
    /* margin: 24px; */
    background-position: center;
    transform: scale(1.4);
   
   
}

.category:hover img:hover{
  transform: scale(1.5);
  transition: 2s ease;
}



.card_detail{
    position: absolute;
    bottom: 50px;
    left: 205px;
    z-index: 1;
    gap: 1rem;
    /* background-color:  red; */
    text-transform: uppercase;
    color: #FED8C3  !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.card_detail h3{
    color: white;
}


.headings-second {

    font-family: cursive;
    font-style: italic;
    font-size: 24px;
    font-weight: bold;
    color: var(--secondary-hue);
    text-align: center;
  
    /* padding-bottom: -70px; */
}


del{
  text-decoration: line-through;
  color: #a7a6a6;
}

