.hero_banner img{
width: 100%;

}

.hero_banner{
    position: relative;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.banner_head{
    position: absolute;
    top: 35%;
    z-index: 99;
    left: 130px;
    width: 40%;
    display: grid;
    gap: 1.5rem;
}

.banner_head h1 , h2{
    line-height: 0.5;
}

.reviews{
    position: fixed;
    transform: rotate(270deg);
    top: 50%;
    z-index: 99;
    left: -49px;
}

.image{

    width: 200px;
    height: 600px;
    border-radius: 10px;
}




